import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a519f17"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  class: "header__logo",
  href: "/"
}
const _hoisted_3 = { class: "header__nav" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_Logo)
    ]),
    _createElementVNode("nav", _hoisted_3, [
      _createElementVNode("a", { href: _ctx.learnMoreLink }, "Learn more", 8, _hoisted_4)
    ])
  ]))
}