
// Core
import { defineComponent } from "vue";

// Utils
import { TOWKNS_NAME } from "@/utils/tokens";

// Components
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: "App",

  components: {
    Logo,
    Modal,
  },

  data: () => ({
    usp: "create",

    inBeta: "In beta.",
    inDevelopment: "In development.",
    inResearch: "In research phase.",

    TOWKNS_NAME,
    openBetaProgramModal: false,
    openWaitingListModal: false,
  }),

  created() {
    this.loopUSPs(["Create", "Maintain", "Distribute"]);
  },

  methods: {
    loopUSPs(words: string[]) {
      let i = 0;
      setInterval(() => {
        this.usp = words[(i = (i + 1) % words.length)];
      }, 1600);
    },
  },
});
