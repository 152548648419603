import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__mask" }
const _hoisted_2 = { class: "modal__wrapper" }
const _hoisted_3 = { class: "modal__container" }
const _hoisted_4 = { class: "modal__content" }
const _hoisted_5 = { class: "modal__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                class: "modal__cancel-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, " Cancel ")
            ])
          ])
        ])
      ], 512), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 3
  }))
}