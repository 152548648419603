
// Core
import { defineComponent } from "vue";

// Components
import Logo from "@/components/Logo.vue";

export default defineComponent({
  name: "footerComponent",

  computed: {
    today() {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const date = new Date();
      const day = date.getDay();

      return days[day];
    },

    currentYear() {
      return new Date().getFullYear();
    },
  },

  components: {
    Logo,
  },
});
