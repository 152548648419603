
// Core
import { defineComponent } from "vue";

// Components
import Logo from "@/components/Logo.vue";

export default defineComponent({
  name: "HeaderComponent",

  components: {
    Logo,
  },

  computed: {
    learnMoreLink() {
      return `${window.location.hostname}/#learn-more`;
    },
  },
});
