
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalComponent",

  watch: {
    show() {
      document.body.style.overflow = "initial";

      if (this.show) document.body.style.overflow = "hidden";

      return this.show;
    },
  },

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
