
// Core
import { defineComponent } from "vue";

// Components
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "App",

  components: {
    Header,
    Footer,
  },
});
